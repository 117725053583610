<div class="table-sport__subhead d-flex align-items-start bg-light">
    <img
        *ngIf="golfTournament.country_iso"
        src="/img/flags/4x3/{{ golfTournament.country_iso | lowercase }}.svg"
        alt=""
        width="20"
        class="mr-2 mt-05"
    />
    <img *ngIf="!golfTournament.country_iso" src="img/sports/golf.png" alt="" width="20" class="mr-2 mt-05" />
    <div class="table-sport__subhead__text mb-0 text-wrap">
        <ng-container *ngIf="golfTournament.playoff">PLAY OFF - </ng-container>
        {{ golfTournament.name }}
        <ng-container *ngIf="golfTournament.country_iso3">({{ golfTournament.country_iso3 }})</ng-container> -
        {{ golfTournament.start_date | livDate : 'dateShort2' }} - {{ golfTournament.end_date | livDate : 'date' }}
        <ng-container *ngIf="golfTournament.status"
            >-
            <strong class="text-emph2">{{ 'web.status-' + golfTournament.status | translate }}</strong>
        </ng-container>
        <div class="text-muted mt-1">
            <ng-container *ngIf="golfTournament.purse">
                {{ 'web.prize_money' | translate }}:
                <strong>{{ golfTournament.purse | number }} {{ golfTournament.currency }}</strong>
            </ng-container>
            <ng-container *ngIf="golfTournament.winning_share">
                {{ 'web.winning_share' | translate }}:
                <strong>{{ golfTournament.winning_share | number }} {{ golfTournament.currency }}</strong>
            </ng-container>
            <ng-container *ngIf="golfTournament.defending_champ_participant">
                &nbsp;&nbsp; {{ 'web.defending_champ' | translate }}:
                <a
                    [routerLink]="[
                        '/page/golf/participant/' +
                            sportName +
                            '-' +
                            sportId +
                            '/' +
                            golfTournament.defending_champ_participant?.id
                    ]"
                >
                    {{ golfTournament.defending_champ_participant?.name }}
                </a>
            </ng-container>
            <ng-container *ngIf="golfTournament.venue?.courses?.[0]?.par">
                &nbsp;&nbsp; PAR: <strong>{{ golfTournament.venue.courses[0]?.par }}</strong>
            </ng-container>
            <ng-container *ngIf="golfTournament.points">
                &nbsp;&nbsp; {{ 'web.golf_points' | translate }}: <strong>{{ golfTournament.points }}</strong>
            </ng-container>
        </div>
    </div>
    <a
        *ngIf="sportId && sportName && showDetailBtn"
        [routerLink]="['/page/golf/tournament', sportName + '-' + sportId, golfTournament.id]"
        class="ml-auto small text-reset"
    >
        <svg class="icon icon--12 mr-2" style="z-index: 9999"><use href="#icon-table" /></svg
        ><ng-container *ngIf="!deviceService.isMobile()">{{ 'web.event-detail' | translate }}</ng-container>
    </a>
</div>
<div *ngIf="golfTournament.participants && golfTournament.participants.length !== 0">
    <div class="w-100 mt-3 mb-5">
        <app-golf-table
            *ngIf="golfTournament.participants && golfTournament.participants.length > 0"
            [data]="golfTournament"
            [sportName]="sportName"
            [sportId]="sportId"
        ></app-golf-table>

        <!--        <app-stage-table *ngIf="golfTournament.participants[2] && golfTournament.participants[2].length > 0"-->
        <!--                         [participants]="golfTournament.participants[2]"-->
        <!--                         [valueTypes]="golfTournament.valueTypes.teams"-->
        <!--                         [stage]="stage"-->
        <!--                         [sportName]="sportName"-->
        <!--                         [sportId]="sportId"-->
        <!--        ></app-stage-table>-->
    </div>
</div>
